import React from 'react';

// Components
import Layout from '../../components/layout';
import Building101 from '../../components/resources/building-101';

import routes from '../../constants/routes';

const Building101Page = () => {
    return (
        <Layout slug={routes.building_101} title="Building 101">
            <Building101 />
        </Layout>
    );
};

export default Building101Page;
